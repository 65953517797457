<template>
	<div class="wrap__content" v-bind:class="{ 'wrap__content--preloader': !applications }">
		<v-preloader v-if="!applications" :message="errorMessage"></v-preloader>

		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['DocumentManagementSupervisor']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.documents') }}
										</div>
										<div v-if="userApplication" class="content__title--subtext">
											{{ userApplication.first_name }} {{ userApplication.last_name }}
											{{ userApplication.middle_name }}({{ userApplication.iin_bin }})
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="row mb-4">
										<div class="col-4">
											<label class="search__label">
												<input
													type="text"
													value=""
													name="filter_search"
													v-model="filters.search"
													:placeholder="$t('placeholder.search')"
												/>
												<button>
													<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M11 19a8 8 0 100-16 8 8 0 000 16zM21 21l-4.35-4.35" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</button>
											</label>
										</div>
										<div class="col-4">
											<multiselect
												class="select__status"
												v-model="filterCategories"
												:options="orderList"
												label="name"
												:clearOnSelect="false"
												:clear-on-select="false"
												:placeholder="$t('placeholder.choose')"
											>
												<template slot="singleLabel" slot-scope="{ option }">{{
													option.name
												}}</template>
												<template slot="noResult">{{ $t('components.not_found') }}</template>
											</multiselect>
										</div>
										<div class="col-4">
											<AddAgreement
												v-if="$store.getters.userPermission(['change_document_all'])"
												:user-id="$route.params.userId"
												@uploaded="apiGetUserDocumentList"
											/>
										</div>
									</div>

									<v-data-table
										:headers="headers"
										:items="filteredlist"
										:loading="false"
										:options.sync="options"
										:server-items-length="totalItems"
										:footer-props="{
											'items-per-page-options': [5, 10, 20, 30, 40, 50],
										}"
										@update:options="pagination"
									>
										<template v-slot:item.name="{ item }">
											{{ item.name }}
										</template>
										<template v-slot:item.created_at="{ item }">
											{{ item.created_at | formatDate }}
										</template>
										<template v-slot:item.status="{ item }">
											<a
												class="tb__table--status"
												:class="{
													'tb__status--success': item.is_deleted == 0,
													'tb__status--error': item.is_deleted == 1,
												}"
											>
												{{ item.is_deleted == 0 ? $t('status.active') : $t('status.blocked') }}
											</a>
										</template>
										<template v-slot:item.action="{ item }">
											<div class="flex">
												<!--
                      <a v-if="$store.getters.userPermission(['read_document'])" :href="'/'+ $i18n.locale +'/account/documents/'+ item.id" class="tb__table--btn tb__table--view" v-tooltip.top-center="{content:$t('tooltip.look'), class: ['tooltip__btn']}">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M.75 9s3-6 8.25-6 8.25 6 8.25 6-3 6-8.25 6S.75 9 .75 9z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/><path d="M9 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" stroke="#5ABB5E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
                      </a>
                      -->
												<a
													:href="urlApi + item.document"
													class="tb__table--btn tb__table--delete"
													target="_blank"
													v-tooltip.top-center="{
														content: $t('button.download'),
														class: ['tooltip__btn'],
													}"
												>
													<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M15.75 11.25v3a1.5 1.5 0 01-1.5 1.5H3.75a1.5 1.5 0 01-1.5-1.5v-3M5.25 7.5L9 11.25l3.75-3.75M9 11.25v-9" stroke="#52A5FC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/></svg>
												</a>
											</div>
										</template>
										<v-alert slot="no-results" :value="true" color="error">
											{{ $t('system_message.search') }}
										</v-alert>
									</v-data-table>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { api, urlApi } from '@/boot/axios'

import Multiselect from 'vue-multiselect'
import AddAgreement from '@/components/AddAgreement.vue'

export default {
	components: {
		Multiselect,
		AddAgreement,
	},
	data() {
		return {
			urlApi: urlApi,

			applications: null,
			totalItems: null,
			userApplication: null,

			errorMessage: {
				status: null,
				text: null,
				messages: null,
			},
			message: {
				status: null,
				text: null,
			},

			blockElemet: null,
			unlockElemet: null,

			filters: {
				search: '',
			},
			options: {
				itemsPerPage: 10,
				page: 1,
			},

			filterCategories: this.$t('orders.userList'),
			orderList: this.$t('orders.user'),

			headers: [
				{ text: 'Id', value: 'id' },
				{ text: this.$t('headers.contract_name'), value: 'name', width: '30vw' },
				{ text: this.$t('headers.register_at'), value: 'created_at' },
				{ text: this.$t('headers.status'), value: 'status' },
				{
					text: this.$t('headers.action'),
					value: 'action',
					align: 'center',
					sortable: false,
					width: '200px',
				},
			],
		}
	},
	props: {
		user: {
			type: Object,
		},
	},

	methods: {
		handleFileUpload() {
			this.message.status = null
			this.message.text = null
			this.message.texts = null
			this.errorMessage = {
				status: null,
				text: null,
				messages: null,
			}
		},
		pagination(options) {
			this.apiGetUserDocumentList(options.page, options.itemsPerPage)
		},
		apiGetUserDocumentList(page = 1, per_page = 10) {
			api
				.get('user/' + this.$route.params.userId + '/document', { params: { page, per_page } })
				.then((response) => {
					if (response.data) {
						this.applications = response.data.data
						this.totalItems = response.data.total
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},

		apiGetUserData() {
			api
				.get('region-user/' + this.$route.params.userId)
				.then((response) => {
					if (response.data) {
						this.userApplication = response.data
					}
				})
				.catch((error) => {
					if (error?.response?.status == 500) {
						this.errorMessage.status = 500
						this.errorMessage.text = this.$t('system_message.500')
					}
					if (error?.response?.status == 401) {
						this.errorMessage.status = 401
						this.errorMessage.text = error.response.data.error_message
						this.$router.push('/' + this.$i18n.locale + '/login')
					}
					if (error?.response?.status == 422) {
						this.errorMessage.status = 422
						this.errorMessage.text = error.response.data.error_message
					}
					if (error?.response?.status == 403) {
						this.errorMessage.status = 403
						this.errorMessage.text = error?.response?.data?.message
					}
				})
		},
	},
	computed: {
		filteredlist() {
			return (
				this.applications?.filter((d) => {
					if (
						(d.name ? d.name.toLowerCase().includes(this.filters.search.toLowerCase()) : true) &&
						(this.filterCategories.status == 'all' || d.is_deleted == this.filterCategories.status)
					) {
						return true
					}
				}) || []
			)
		},
	},
	mounted() {
		this.apiGetUserData()
	},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.users_parent'),
			}
		},
		meta: [],
	},
}
</script>
<style>
header .header__row {
	display: flex;
}
</style>
